// source: revumee.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.com.revumee.SessionMsg', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.ContentCase', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataReq', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataReq.Param', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataReq.Param.Id', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataResp', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataResp.Param', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.DataResp.Param.Type', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.ErrorResp', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.Status', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.ContentCase', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginReq', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginResp', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.VersionReq', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.VersionReq.System', null, global);
goog.exportSymbol('proto.com.revumee.SessionMsg.SessionControl.VersionResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.revumee.SessionMsg.oneofGroups_);
};
goog.inherits(proto.com.revumee.SessionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.displayName = 'proto.com.revumee.SessionMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.ErrorResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.ErrorResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.ErrorResp.displayName = 'proto.com.revumee.SessionMsg.ErrorResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.DataReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.revumee.SessionMsg.DataReq.repeatedFields_, null);
};
goog.inherits(proto.com.revumee.SessionMsg.DataReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.DataReq.displayName = 'proto.com.revumee.SessionMsg.DataReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.DataReq.Param = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.DataReq.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.DataReq.Param.displayName = 'proto.com.revumee.SessionMsg.DataReq.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.DataResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.revumee.SessionMsg.DataResp.repeatedFields_, null);
};
goog.inherits(proto.com.revumee.SessionMsg.DataResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.DataResp.displayName = 'proto.com.revumee.SessionMsg.DataResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.DataResp.Param = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.DataResp.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.DataResp.Param.displayName = 'proto.com.revumee.SessionMsg.DataResp.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.displayName = 'proto.com.revumee.SessionMsg.SessionControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.VersionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.VersionReq.displayName = 'proto.com.revumee.SessionMsg.SessionControl.VersionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.VersionResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.VersionResp.displayName = 'proto.com.revumee.SessionMsg.SessionControl.VersionResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.displayName = 'proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.displayName = 'proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.displayName = 'proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.displayName = 'proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.revumee.SessionMsg.SessionControl.LoginReq.repeatedFields_, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.LoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.LoginReq.displayName = 'proto.com.revumee.SessionMsg.SessionControl.LoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.displayName = 'proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.revumee.SessionMsg.SessionControl.LoginResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.revumee.SessionMsg.SessionControl.LoginResp.displayName = 'proto.com.revumee.SessionMsg.SessionControl.LoginResp';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.revumee.SessionMsg.oneofGroups_ = [[3,4,5,6]];

/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.ContentCase = {
  CONTENT_NOT_SET: 0,
  SESSION_CONTROL: 3,
  DATA_REQ: 4,
  DATA_RESP: 5,
  ERROR_RESP: 6
};

/**
 * @return {proto.com.revumee.SessionMsg.ContentCase}
 */
proto.com.revumee.SessionMsg.prototype.getContentCase = function() {
  return /** @type {proto.com.revumee.SessionMsg.ContentCase} */(jspb.Message.computeOneofCase(this, proto.com.revumee.SessionMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userTokenId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionControl: (f = msg.getSessionControl()) && proto.com.revumee.SessionMsg.SessionControl.toObject(includeInstance, f),
    dataReq: (f = msg.getDataReq()) && proto.com.revumee.SessionMsg.DataReq.toObject(includeInstance, f),
    dataResp: (f = msg.getDataResp()) && proto.com.revumee.SessionMsg.DataResp.toObject(includeInstance, f),
    errorResp: (f = msg.getErrorResp()) && proto.com.revumee.SessionMsg.ErrorResp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg}
 */
proto.com.revumee.SessionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg;
  return proto.com.revumee.SessionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg}
 */
proto.com.revumee.SessionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserTokenId(value);
      break;
    case 3:
      var value = new proto.com.revumee.SessionMsg.SessionControl;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.deserializeBinaryFromReader);
      msg.setSessionControl(value);
      break;
    case 4:
      var value = new proto.com.revumee.SessionMsg.DataReq;
      reader.readMessage(value,proto.com.revumee.SessionMsg.DataReq.deserializeBinaryFromReader);
      msg.setDataReq(value);
      break;
    case 5:
      var value = new proto.com.revumee.SessionMsg.DataResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.DataResp.deserializeBinaryFromReader);
      msg.setDataResp(value);
      break;
    case 6:
      var value = new proto.com.revumee.SessionMsg.ErrorResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.ErrorResp.deserializeBinaryFromReader);
      msg.setErrorResp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserTokenId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionControl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.revumee.SessionMsg.SessionControl.serializeBinaryToWriter
    );
  }
  f = message.getDataReq();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.revumee.SessionMsg.DataReq.serializeBinaryToWriter
    );
  }
  f = message.getDataResp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.revumee.SessionMsg.DataResp.serializeBinaryToWriter
    );
  }
  f = message.getErrorResp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.revumee.SessionMsg.ErrorResp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.ErrorResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.ErrorResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.ErrorResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.ErrorResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.ErrorResp}
 */
proto.com.revumee.SessionMsg.ErrorResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.ErrorResp;
  return proto.com.revumee.SessionMsg.ErrorResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.ErrorResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.ErrorResp}
 */
proto.com.revumee.SessionMsg.ErrorResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.ErrorResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.ErrorResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.ErrorResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.ErrorResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string error_message = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.ErrorResp.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.ErrorResp} returns this
 */
proto.com.revumee.SessionMsg.ErrorResp.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.revumee.SessionMsg.DataReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.DataReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.DataReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.DataReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.com.revumee.SessionMsg.DataReq.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.DataReq}
 */
proto.com.revumee.SessionMsg.DataReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.DataReq;
  return proto.com.revumee.SessionMsg.DataReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.DataReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.DataReq}
 */
proto.com.revumee.SessionMsg.DataReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.com.revumee.SessionMsg.DataReq.Param;
      reader.readMessage(value,proto.com.revumee.SessionMsg.DataReq.Param.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.DataReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.DataReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.DataReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.revumee.SessionMsg.DataReq.Param.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.DataReq.Param.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.DataReq.Param} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataReq.Param.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param}
 */
proto.com.revumee.SessionMsg.DataReq.Param.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.DataReq.Param;
  return proto.com.revumee.SessionMsg.DataReq.Param.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.DataReq.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param}
 */
proto.com.revumee.SessionMsg.DataReq.Param.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.DataReq.Param.Id} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.DataReq.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.DataReq.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataReq.Param.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.DataReq.Param.Id = {
  UNKNOWN_ID: 0,
  QUERY: 1
};

/**
 * optional Id id = 1;
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param.Id}
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.getId = function() {
  return /** @type {!proto.com.revumee.SessionMsg.DataReq.Param.Id} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.DataReq.Param.Id} value
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param} returns this
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param} returns this
 */
proto.com.revumee.SessionMsg.DataReq.Param.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Param params = 2;
 * @return {!Array<!proto.com.revumee.SessionMsg.DataReq.Param>}
 */
proto.com.revumee.SessionMsg.DataReq.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.com.revumee.SessionMsg.DataReq.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.revumee.SessionMsg.DataReq.Param, 2));
};


/**
 * @param {!Array<!proto.com.revumee.SessionMsg.DataReq.Param>} value
 * @return {!proto.com.revumee.SessionMsg.DataReq} returns this
*/
proto.com.revumee.SessionMsg.DataReq.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.revumee.SessionMsg.DataReq.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.revumee.SessionMsg.DataReq.Param}
 */
proto.com.revumee.SessionMsg.DataReq.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.revumee.SessionMsg.DataReq.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.revumee.SessionMsg.DataReq} returns this
 */
proto.com.revumee.SessionMsg.DataReq.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.revumee.SessionMsg.DataResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.DataResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.DataResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.DataResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.com.revumee.SessionMsg.DataResp.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.DataResp}
 */
proto.com.revumee.SessionMsg.DataResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.DataResp;
  return proto.com.revumee.SessionMsg.DataResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.DataResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.DataResp}
 */
proto.com.revumee.SessionMsg.DataResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.revumee.SessionMsg.DataResp.Param;
      reader.readMessage(value,proto.com.revumee.SessionMsg.DataResp.Param.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.DataResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.DataResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.DataResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.revumee.SessionMsg.DataResp.Param.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.DataResp.Param.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.DataResp.Param} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataResp.Param.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param}
 */
proto.com.revumee.SessionMsg.DataResp.Param.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.DataResp.Param;
  return proto.com.revumee.SessionMsg.DataResp.Param.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.DataResp.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param}
 */
proto.com.revumee.SessionMsg.DataResp.Param.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.DataResp.Param.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.DataResp.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.DataResp.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.DataResp.Param.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.DataResp.Param.Type = {
  UNKNOWN_TYPE: 0,
  STRING: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param.Type}
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.getType = function() {
  return /** @type {!proto.com.revumee.SessionMsg.DataResp.Param.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.DataResp.Param.Type} value
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param} returns this
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param} returns this
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param} returns this
 */
proto.com.revumee.SessionMsg.DataResp.Param.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Param params = 1;
 * @return {!Array<!proto.com.revumee.SessionMsg.DataResp.Param>}
 */
proto.com.revumee.SessionMsg.DataResp.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.com.revumee.SessionMsg.DataResp.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.revumee.SessionMsg.DataResp.Param, 1));
};


/**
 * @param {!Array<!proto.com.revumee.SessionMsg.DataResp.Param>} value
 * @return {!proto.com.revumee.SessionMsg.DataResp} returns this
*/
proto.com.revumee.SessionMsg.DataResp.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.revumee.SessionMsg.DataResp.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.revumee.SessionMsg.DataResp.Param}
 */
proto.com.revumee.SessionMsg.DataResp.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.revumee.SessionMsg.DataResp.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.revumee.SessionMsg.DataResp} returns this
 */
proto.com.revumee.SessionMsg.DataResp.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.revumee.SessionMsg.SessionControl.oneofGroups_ = [[1,2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.ContentCase = {
  CONTENT_NOT_SET: 0,
  VERSION_REQ: 1,
  VERSION_RESP: 2,
  INVITE_CODE_CHECK_REQ: 3,
  INVITE_CODE_CHECK_RESP: 4,
  ACCOUNT_CREATE_REQ: 5,
  ACCOUNT_CREATE_RESP: 6,
  LOGIN_REQ: 7,
  LOGIN_RESP: 8
};

/**
 * @return {proto.com.revumee.SessionMsg.SessionControl.ContentCase}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getContentCase = function() {
  return /** @type {proto.com.revumee.SessionMsg.SessionControl.ContentCase} */(jspb.Message.computeOneofCase(this, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionReq: (f = msg.getVersionReq()) && proto.com.revumee.SessionMsg.SessionControl.VersionReq.toObject(includeInstance, f),
    versionResp: (f = msg.getVersionResp()) && proto.com.revumee.SessionMsg.SessionControl.VersionResp.toObject(includeInstance, f),
    inviteCodeCheckReq: (f = msg.getInviteCodeCheckReq()) && proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.toObject(includeInstance, f),
    inviteCodeCheckResp: (f = msg.getInviteCodeCheckResp()) && proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.toObject(includeInstance, f),
    accountCreateReq: (f = msg.getAccountCreateReq()) && proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.toObject(includeInstance, f),
    accountCreateResp: (f = msg.getAccountCreateResp()) && proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.toObject(includeInstance, f),
    loginReq: (f = msg.getLoginReq()) && proto.com.revumee.SessionMsg.SessionControl.LoginReq.toObject(includeInstance, f),
    loginResp: (f = msg.getLoginResp()) && proto.com.revumee.SessionMsg.SessionControl.LoginResp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl}
 */
proto.com.revumee.SessionMsg.SessionControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl;
  return proto.com.revumee.SessionMsg.SessionControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl}
 */
proto.com.revumee.SessionMsg.SessionControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.revumee.SessionMsg.SessionControl.VersionReq;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.VersionReq.deserializeBinaryFromReader);
      msg.setVersionReq(value);
      break;
    case 2:
      var value = new proto.com.revumee.SessionMsg.SessionControl.VersionResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.VersionResp.deserializeBinaryFromReader);
      msg.setVersionResp(value);
      break;
    case 3:
      var value = new proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.deserializeBinaryFromReader);
      msg.setInviteCodeCheckReq(value);
      break;
    case 4:
      var value = new proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.deserializeBinaryFromReader);
      msg.setInviteCodeCheckResp(value);
      break;
    case 5:
      var value = new proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.deserializeBinaryFromReader);
      msg.setAccountCreateReq(value);
      break;
    case 6:
      var value = new proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.deserializeBinaryFromReader);
      msg.setAccountCreateResp(value);
      break;
    case 7:
      var value = new proto.com.revumee.SessionMsg.SessionControl.LoginReq;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.LoginReq.deserializeBinaryFromReader);
      msg.setLoginReq(value);
      break;
    case 8:
      var value = new proto.com.revumee.SessionMsg.SessionControl.LoginResp;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.LoginResp.deserializeBinaryFromReader);
      msg.setLoginResp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionReq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.revumee.SessionMsg.SessionControl.VersionReq.serializeBinaryToWriter
    );
  }
  f = message.getVersionResp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.revumee.SessionMsg.SessionControl.VersionResp.serializeBinaryToWriter
    );
  }
  f = message.getInviteCodeCheckReq();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.serializeBinaryToWriter
    );
  }
  f = message.getInviteCodeCheckResp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.serializeBinaryToWriter
    );
  }
  f = message.getAccountCreateReq();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.serializeBinaryToWriter
    );
  }
  f = message.getAccountCreateResp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.serializeBinaryToWriter
    );
  }
  f = message.getLoginReq();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.revumee.SessionMsg.SessionControl.LoginReq.serializeBinaryToWriter
    );
  }
  f = message.getLoginResp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.revumee.SessionMsg.SessionControl.LoginResp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.VersionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    system: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionReq}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.VersionReq;
  return proto.com.revumee.SessionMsg.SessionControl.VersionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionReq}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.SessionControl.VersionReq.System} */ (reader.readEnum());
      msg.setSystem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.VersionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.System = {
  UNKNOWN: 0,
  APP_SERVER: 1
};

/**
 * optional System system = 1;
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionReq.System}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.prototype.getSystem = function() {
  return /** @type {!proto.com.revumee.SessionMsg.SessionControl.VersionReq.System} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionReq.System} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.VersionReq.prototype.setSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.VersionResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionResp}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.VersionResp;
  return proto.com.revumee.SessionMsg.SessionControl.VersionResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionResp}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.VersionResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.VersionResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.VersionResp} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.VersionResp.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    inviteCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq;
  return proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInviteCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string invite_code = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.prototype.getInviteCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq.prototype.setInviteCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp;
  return proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status = {
  UNKNOWN: 0,
  VALID: 1,
  NOT_VALID: 2,
  ALREADY_IN_USE: 3
};

/**
 * optional Status status = 1;
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status}
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.prototype.getStatus = function() {
  return /** @type {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.Status} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    oauthToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq;
  return proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOauthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string oauth_token = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.getOauthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.setOauthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp;
  return proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.Status = {
  UNKNOWN: 0,
  CREATED: 1,
  TOKEN_INVALID: 2
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.LoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.LoginReq;
  return proto.com.revumee.SessionMsg.SessionControl.LoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = new proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param;
      reader.readMessage(value,proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.LoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action = {
  UNKNOWN: 0,
  LOGIN: 1,
  LOGOUT: 2,
  TOKEN_UPDATE: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param;
  return proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id = {
  UNKNOWN_PARAM: 0,
  USERNAME: 1,
  PASSWORD: 2,
  TOKEN: 3
};

/**
 * optional Id id = 1;
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.getId = function() {
  return /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.Id} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Action action = 1;
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.getAction = function() {
  return /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Action} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Param params = 2;
 * @return {!Array<!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param>}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param, 2));
};


/**
 * @param {!Array<!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param>} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.revumee.SessionMsg.SessionControl.LoginReq.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginReq} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.LoginReq.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.revumee.SessionMsg.SessionControl.LoginResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginResp}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.revumee.SessionMsg.SessionControl.LoginResp;
  return proto.com.revumee.SessionMsg.SessionControl.LoginResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginResp}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.revumee.SessionMsg.SessionControl.LoginResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status = {
  UNKNOWN: 0,
  SUCCESSFUL: 1,
  FAILED: 2
};

/**
 * optional Status status = 1;
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status}
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.prototype.getStatus = function() {
  return /** @type {!proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.revumee.SessionMsg.SessionControl.LoginResp.Status} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl.LoginResp} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.LoginResp.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional VersionReq version_req = 1;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.VersionReq}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getVersionReq = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.VersionReq} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.VersionReq, 1));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.VersionReq|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setVersionReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearVersionReq = function() {
  return this.setVersionReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasVersionReq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VersionResp version_resp = 2;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.VersionResp}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getVersionResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.VersionResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.VersionResp, 2));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.VersionResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setVersionResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearVersionResp = function() {
  return this.setVersionResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasVersionResp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InviteCodeCheckReq invite_code_check_req = 3;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getInviteCodeCheckReq = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq, 3));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckReq|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setInviteCodeCheckReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearInviteCodeCheckReq = function() {
  return this.setInviteCodeCheckReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasInviteCodeCheckReq = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InviteCodeCheckResp invite_code_check_resp = 4;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getInviteCodeCheckResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp, 4));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.InviteCodeCheckResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setInviteCodeCheckResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearInviteCodeCheckResp = function() {
  return this.setInviteCodeCheckResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasInviteCodeCheckResp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AccountCreateReq account_create_req = 5;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getAccountCreateReq = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq, 5));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.AccountCreateReq|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setAccountCreateReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearAccountCreateReq = function() {
  return this.setAccountCreateReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasAccountCreateReq = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccountCreateResp account_create_resp = 6;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getAccountCreateResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp, 6));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.AccountCreateResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setAccountCreateResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearAccountCreateResp = function() {
  return this.setAccountCreateResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasAccountCreateResp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LoginReq login_req = 7;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.LoginReq}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getLoginReq = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.LoginReq} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.LoginReq, 7));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.LoginReq|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setLoginReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearLoginReq = function() {
  return this.setLoginReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasLoginReq = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LoginResp login_resp = 8;
 * @return {?proto.com.revumee.SessionMsg.SessionControl.LoginResp}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.getLoginResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl.LoginResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl.LoginResp, 8));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl.LoginResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
*/
proto.com.revumee.SessionMsg.SessionControl.prototype.setLoginResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.com.revumee.SessionMsg.SessionControl.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg.SessionControl} returns this
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.clearLoginResp = function() {
  return this.setLoginResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.SessionControl.prototype.hasLoginResp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string session_uuid = 1;
 * @return {string}
 */
proto.com.revumee.SessionMsg.prototype.getSessionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.setSessionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_token_id = 2;
 * @return {string}
 */
proto.com.revumee.SessionMsg.prototype.getUserTokenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.setUserTokenId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SessionControl session_control = 3;
 * @return {?proto.com.revumee.SessionMsg.SessionControl}
 */
proto.com.revumee.SessionMsg.prototype.getSessionControl = function() {
  return /** @type{?proto.com.revumee.SessionMsg.SessionControl} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.SessionControl, 3));
};


/**
 * @param {?proto.com.revumee.SessionMsg.SessionControl|undefined} value
 * @return {!proto.com.revumee.SessionMsg} returns this
*/
proto.com.revumee.SessionMsg.prototype.setSessionControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.revumee.SessionMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.clearSessionControl = function() {
  return this.setSessionControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.prototype.hasSessionControl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DataReq data_req = 4;
 * @return {?proto.com.revumee.SessionMsg.DataReq}
 */
proto.com.revumee.SessionMsg.prototype.getDataReq = function() {
  return /** @type{?proto.com.revumee.SessionMsg.DataReq} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.DataReq, 4));
};


/**
 * @param {?proto.com.revumee.SessionMsg.DataReq|undefined} value
 * @return {!proto.com.revumee.SessionMsg} returns this
*/
proto.com.revumee.SessionMsg.prototype.setDataReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.revumee.SessionMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.clearDataReq = function() {
  return this.setDataReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.prototype.hasDataReq = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DataResp data_resp = 5;
 * @return {?proto.com.revumee.SessionMsg.DataResp}
 */
proto.com.revumee.SessionMsg.prototype.getDataResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.DataResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.DataResp, 5));
};


/**
 * @param {?proto.com.revumee.SessionMsg.DataResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg} returns this
*/
proto.com.revumee.SessionMsg.prototype.setDataResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.revumee.SessionMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.clearDataResp = function() {
  return this.setDataResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.prototype.hasDataResp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResp error_resp = 6;
 * @return {?proto.com.revumee.SessionMsg.ErrorResp}
 */
proto.com.revumee.SessionMsg.prototype.getErrorResp = function() {
  return /** @type{?proto.com.revumee.SessionMsg.ErrorResp} */ (
    jspb.Message.getWrapperField(this, proto.com.revumee.SessionMsg.ErrorResp, 6));
};


/**
 * @param {?proto.com.revumee.SessionMsg.ErrorResp|undefined} value
 * @return {!proto.com.revumee.SessionMsg} returns this
*/
proto.com.revumee.SessionMsg.prototype.setErrorResp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.revumee.SessionMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.revumee.SessionMsg} returns this
 */
proto.com.revumee.SessionMsg.prototype.clearErrorResp = function() {
  return this.setErrorResp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.revumee.SessionMsg.prototype.hasErrorResp = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.com.revumee);
