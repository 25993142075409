import React, { useEffect } from 'react';
// react-meta-tags is not typescript-compliant
// the other way to fix this is to turn off "no-implicit-any" in tsconfig, but that's a hammer of a solution to the whole project
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { MetaTags } from 'react-meta-tags';
import { connect } from 'react-redux';
import { createStyles, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import { grpc } from '@improbable-eng/grpc-web';
import { SessionMsg } from '../../generated/main/grpcweb/revumee_pb';
import { RevumeeService } from '../../generated/main/grpcweb/revumee_pb_service';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			background: '#FDD835',
			marginTop: theme.spacing(1),
		},
		paper: {
			marginTop: theme.spacing(3),
			width: '80%',
			overflowX: 'auto',
			marginBottom: theme.spacing(2),
		},
		inviteCodeField: {
			width: '80%',
		},
		validInput: {
			color: 'green',
		},
		invalidInput: {
			color: 'red',
		},
	}),
);

function Invite(): JSX.Element {
	const classes = useStyles();

	const [inviteCode, setInviteCode] = React.useState('');
	const [errorText, setErrorText] = React.useState('');
	const [validationStyle, setValidationStyle] = React.useState('validInput');

	let client: grpc.Client<grpc.ProtobufMessage, grpc.ProtobufMessage>;
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	let auth2;

	function googleLogin(): void {

		const userChanged = (user: string): void => {
			console.log('User now: ', user);
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			const googleUser = auth2.currentUser.get();
			const id = googleUser.getId();
			const auth = googleUser.getAuthResponse();
			console.log(`google login uid: ${id}, auth: ${auth}`);

			const profile = googleUser.getBasicProfile();
			console.log(`ID: ${profile.getId()}`);
			console.log(`Full Name: ${profile.getName()}`);
			console.log(`Given Name: ${profile.getGivenName()}`);
			console.log(`Family Name: ${profile.getFamilyName()}`);
			console.log(`Image URL: ${profile.getImageUrl()}`);
			console.log(`Email: ${profile.getEmail()}`);
		};


		const initSigninV2 = (): void => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			auth2 = gapi.auth2.init({
				clientId: '513752266902-b2qmb1q6t81tdk7o9nsinc07bk777qj7.apps.googleusercontent.com',
				scope: 'profile'
			});

			if (auth2.isSignedIn.get() !== true) {
				auth2.signIn();
			}

			auth2.currentUser.listen(userChanged);
		};

		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		gapi.load('auth2', initSigninV2);
	}

	function setupClient(): void {
		client = grpc.client(RevumeeService.Session, {
			host: 'https://test.revumee.com:443',
			transport: grpc.WebsocketTransport(),
			debug: true,
		});

		const onMessageFn = (sessionMsg: SessionMsg): void => {
			const sessionControl: SessionMsg.SessionControl = sessionMsg.getSessionControl() as SessionMsg.SessionControl;
			const inviteCodeCheckResp: SessionMsg.SessionControl.InviteCodeCheckResp = sessionControl.getInviteCodeCheckResp() as SessionMsg.SessionControl.InviteCodeCheckResp;
			const isValid: boolean = (inviteCodeCheckResp.getStatus() === SessionMsg.SessionControl.InviteCodeCheckResp.Status.VALID);
			switch (inviteCodeCheckResp.getStatus()) {
				case SessionMsg.SessionControl.InviteCodeCheckResp.Status.VALID:
					setErrorText('');
					googleLogin();
					break;
				case SessionMsg.SessionControl.InviteCodeCheckResp.Status.ALREADY_IN_USE:
					setErrorText('Invite Code Already Used');
					break;
				default:
					setErrorText('Invalid Invite Code');
			}
			setValidationStyle(isValid ? 'validInput' : 'invalidInput');
			console.log('invite code resp: ', inviteCodeCheckResp.getStatus());
			client.close();
		};

		client.onMessage(onMessageFn);

		client.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata): void => {
			console.log('onEnd', status, statusMessage, trailers);
		});

		client.start(new grpc.Metadata({ 'RevumeeService': 'version' }));
	}

	useEffect((): (void | (() => (void | undefined))) => {

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/platform.js';
		script.async = true;
		document.body.appendChild(script);

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		return function cleanup() {
			return undefined;
		};
	});

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>): void => {
		const inputVal: string = event.target.value;
		const uuidPos: number = inputVal.search('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
		const isValid: boolean = (event.target.value.length === 0) || ((uuidPos === 0) && (event.target.value.length === 36));
		setInviteCode(event.target.value);
		setErrorText(isValid ? '' : 'Invalid Invite Code');
		setValidationStyle(isValid ? 'validInput' : 'invalidInput');


		// now validate the code against the appserver
		if (isValid) {
			setupClient();
			const msg = new SessionMsg();
			msg.setSessionUuid('12345678');
			const sessionControl = new SessionMsg.SessionControl();
			const inviteCodeCheckReq = new SessionMsg.SessionControl.InviteCodeCheckReq();
			inviteCodeCheckReq.setInviteCode(inputVal);
			sessionControl.setInviteCodeCheckReq(inviteCodeCheckReq);
			msg.setSessionControl(sessionControl);
			client.send(msg);
		}
	};

	return (
		<div>
			<MetaTags>
				<meta id="google-signin-client_id" name="google-signin-client_id" content="513752266902-b2qmb1q6t81tdk7o9nsinc07bk777qj7.apps.googleusercontent.com" />
			</MetaTags>
			<TextField
				id="standard-name"
				label="Invite Code"
				className={classes.inviteCodeField}
				value={inviteCode}
				onChange={handleChange()}
				helperText={errorText}
				margin="normal"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<AccountCircle/>
						</InputAdornment>
					),
					className: classes[validationStyle],
				}}
			/>
		</div>
	);
}

export default connect(
)(Invite);