export interface GrpcState {
	lastMessage: string;
	timestamp: number;
}

export interface SystemState {
	loggedIn: boolean;
	session: string;
	userName: string;
}

export interface NavOpenState {
	isOpen: boolean;
}

export const UPDATE_SESSION = 'UPDATE_SESSION';
export const SEND_GRPC_MESSAGE = 'SEND_GRPC_MESSAGE';
export const RECV_GRPC_MESSAGE = 'RECV_GRPC_MESSAGE';
export const NAV_SET_IS_OPEN = 'NAV_SET_IS_OPEN';
export const NAV_SET_IS_CLOSED = 'NAV_SET_IS_CLOSED';
export const NAV_TOGGLE_OPEN = 'NAV_TOGGLE_OPEN';

export interface UpdateSessionAction {
	type: typeof UPDATE_SESSION;
	payload: SystemState;
}

export interface SendGrpcMessageAction {
	type: typeof SEND_GRPC_MESSAGE;
	payload: GrpcState;
}

export interface RecvGrpcMessageAction {
	type: typeof RECV_GRPC_MESSAGE;
	payload: GrpcState;
}

export interface NavSetOpenAction {
	type: typeof NAV_SET_IS_OPEN;
}

export interface NavSetClosedAction {
	type: typeof NAV_SET_IS_CLOSED;
}

export interface NavToggleOpenAction {
	type: typeof NAV_TOGGLE_OPEN;
}

export type SystemActionTypes = UpdateSessionAction
export type GrpcActionTypes = SendGrpcMessageAction | RecvGrpcMessageAction;
export type NavActionTypes = NavSetOpenAction | NavSetClosedAction | NavToggleOpenAction
