import React from 'react';
import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import NavDrawer from './NavDrawer';
import { AppState } from '../redux';
import { setNavOpen, toggleNavOpen } from '../redux/system/actions';
import { NavOpenState } from '../redux/system/types';
// import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			color: 'white',
			background: 'black',
			secondary: 'white',
		},
		appBar: {
			position: 'absolute',
			width: '100%',
			zIndex: 1400,
			background: 'black',
			secondary: 'white',
		},
		menuButton: {
			marginRight: theme.spacing(2),
			color: 'white',
			background: 'black',
			secondary: 'white',
		},
		title: {
			flexGrow: 1,
			marginRight: '48px',
		},
	}),
);

interface NavBarProps {
	nav: NavOpenState;
	openNav: typeof setNavOpen;
	toggleNav: typeof toggleNavOpen;
}

function NavBar(props: NavBarProps): React.ReactElement {
	const classes = useStyles();

	const { nav } = props;
	const { toggleNav } = props;
	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" className={classes.menuButton} color="primary" aria-label="Menu" onClick={toggleNav}>
						<MenuIcon/>
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Revumee
					</Typography>
					{/* <Button color="inherit">Login</Button> */}
				</Toolbar>
			</AppBar>
			<NavDrawer nav={nav}/>
		</div>
	);
}

const mapStateToProps = (state: AppState): object => ({
	nav: state.nav
});

const matchDispatchToProps = {
	openNav: setNavOpen,
	toggleNav: toggleNavOpen
};

export default connect(
	mapStateToProps,
	matchDispatchToProps,
)(NavBar);