import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { GrpcState } from '../redux/system/types';
import { AppState } from '../redux';
import { recvGrpcMessage } from '../redux/system/actions';
import GrpcTest from '../components/GrpcTest';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			background: '#FDD835',
			marginTop: theme.spacing(2),
		},
	}),
);

interface GrapDrawProps {
	recvMessage: typeof recvGrpcMessage;
	grpc: GrpcState;
}

function GrpcDraw(props: GrapDrawProps): JSX.Element {
	const { grpc } = props;
	const classes = useStyles();

	useEffect((): (void | (() => (void | undefined))) => {
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		return function cleanup() {
			grpc.lastMessage = '';
			grpc.timestamp = 0;
			return undefined;
		};
	});

	return (
		<div className={classes.root}>
			<h2 className={classes.root}>GRPC Test</h2>

			Message: {grpc.lastMessage} timestamp: {grpc.timestamp}
			<GrpcTest/>
		</div>
	);
}

const mapStateToProps = (state: AppState): object => ({
	grpc: state.grpc,
});

export default connect(
	mapStateToProps,
	{
		recvMessage: recvGrpcMessage,
	},
)(GrpcDraw);