// package: com.revumee
// file: revumee.proto

var revumee_pb = require("./revumee_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RevumeeService = (function () {
  function RevumeeService() {}
  RevumeeService.serviceName = "com.revumee.RevumeeService";
  return RevumeeService;
}());

RevumeeService.Session = {
  methodName: "Session",
  service: RevumeeService,
  requestStream: true,
  responseStream: true,
  requestType: revumee_pb.SessionMsg,
  responseType: revumee_pb.SessionMsg
};

exports.RevumeeService = RevumeeService;

function RevumeeServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RevumeeServiceClient.prototype.session = function session(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(RevumeeService.Session, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.RevumeeServiceClient = RevumeeServiceClient;

