import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { sendGrpcMessage } from './system/actions';
import { AppState } from './index';

function exampleAPI(message: string): Promise<string> {
	return Promise.resolve(message);
}

const doSendGrpMessage = (
	message: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
	const asyncResp = await exampleAPI(message);
	dispatch(
		sendGrpcMessage({
			lastMessage: asyncResp,
			timestamp: new Date().getTime()
		})
	);
};

export default doSendGrpMessage;