import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { grpcReducer, systemReducer, navOpenStateReducer } from './system/reducers';

const rootReducer = combineReducers({
	system: systemReducer,
	grpc: grpcReducer,
	nav: navOpenStateReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(): Store {
	const middlewares = [thunkMiddleware];
	const middleWareEnhancer = applyMiddleware(...middlewares);

	return createStore(
		rootReducer,
		composeWithDevTools(middleWareEnhancer)
	);
}