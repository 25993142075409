import * as React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import App from './main/containers/App';
import configureStore from './main/redux';
import './index.css';
import registerServiceWorker from './main/utils/registerServiceWorker';
import { SystemState, GrpcState, NavOpenState } from './main/redux/system/types';

const reduxStore = configureStore();

const systemState: SystemState = {
	loggedIn: false,
	session: '',
	userName: 'jeff',
};

const grpcState: GrpcState = {
	lastMessage: '',
	timestamp: 0,
};

const navDrawerState: NavOpenState = {
	isOpen: false,
};

const Root = (): React.ReactElement => (
	<Provider store={reduxStore}>
		<App system={systemState} grpc={grpcState} nav={navDrawerState}/>
	</Provider>
);

render(<Root/>, document.getElementById('root'));

registerServiceWorker();
