import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Router } from '@reach/router';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles, StyleRules } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { AppState } from '../redux';
import { GrpcState, NavOpenState, SystemState } from '../redux/system/types';
import { recvGrpcMessage, sendGrpcMessage, updateSession } from '../redux/system/actions';
import NavBar from './NavBar';
import doSendGrpMessage from '../redux/thunks';
import Info from './Info';
import GrpcDraw from './GrpcDraw';
import Home from './Home';
import Invite from './Invite';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			width: '80%',
			marginTop: theme.spacing(5),
		},
		app: {
			textAlign: 'center',
		},
	}),
);

interface AppProps {
	sendMessage: typeof sendGrpcMessage;
	recvMessage: typeof recvGrpcMessage;
	updateSession: typeof updateSession;
	system: SystemState;
	grpc: GrpcState;
	nav: NavOpenState;
	thunkSendMessage: (message: string) => void;
}

function App(props: AppProps): JSX.Element {
	const { nav } = props;
	const classes = useStyles();
	const theme = createMuiTheme();

	return (
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<div className={classes.app}>
					<NavBar nav={nav}/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<Router>
						<RouterPage path="/info" pageComponent={<Info />} />
						<RouterPage path="/invite" pageComponent={<Invite />} />
						<RouterPage path="/grpc" pageComponent={<GrpcDraw grpc={new class implements GrpcState {
							public lastMessage: 'string';

							public timestamp: 0;
						}}/>} />
						<RouterPage path="/" pageComponent={<Home />} />
					</Router>
					<br/>
					<img src={`${process.env.PUBLIC_URL}./images/bee.svg`} width={192} height={192} alt=''/>
				</div>
			</ThemeProvider>
		</React.StrictMode>
	);
}

const RouterPage = (
	props: { pageComponent: JSX.Element } & RouteComponentProps
): JSX.Element => props.pageComponent;

const mapStateToProps = (state: AppState): object => ({
	system: state.system,
	grpc: state.grpc,
	nav: state.nav,
});

export default connect(
	mapStateToProps,
	{
		sendMessage: sendGrpcMessage,
		recvMessage: recvGrpcMessage,
		updateSession,
		thunkSendMessage: doSendGrpMessage,
	},
)(App);