import React from 'react';
import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Info, Home, Email } from '@material-ui/icons';
import { Link } from '@reach/router';
import { AppState } from '../redux';
import { setNavClosed, toggleNavOpen } from '../redux/system/actions';
import { NavOpenState } from '../redux/system/types';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		toolbar: theme.mixins.toolbar,
		menuButton: {
			marginRight: theme.spacing(2),
			color: 'white',
			background: 'black',
			secondary: 'white',
		},
		title: {
			flexGrow: 1,
		},
		list: {
			width: 250,
		},
		listFull: {
			width: 'auto',
		},
	}),
);

interface NavDrawerProps {
	nav: NavOpenState;
	closeNav: typeof setNavClosed;
	toggleNav: typeof toggleNavOpen;
}

function NavDrawer(props: NavDrawerProps): React.ReactElement {
	const { nav } = props;
	const { closeNav } = props;
	const classes = useStyles();

	return (
		<div>
			<Drawer className={classes.drawer} classes={{ paper: classes.drawerPaper }} open={nav.isOpen} onClick={closeNav}>
				<div className={classes.toolbar}/>
				<div tabIndex={0} role='button' onClick={toggleNavOpen} onKeyDown={toggleNavOpen}>
					<List>
						<ListItem button key='Home' component={Link} to="/">
							<ListItemIcon><Home/></ListItemIcon>
							<ListItemText primary='Home'/>
						</ListItem>
					</List>
					<Divider/>
					<List>
						<ListItem button key='Invite' component={Link} to="/invite">
							<ListItemIcon><Email/></ListItemIcon>
							<ListItemText primary='Invite'/>
						</ListItem>
					</List>
					<List>
						<ListItem button key='Info' component={Link} to="/info">
							<ListItemIcon><Info/></ListItemIcon>
							<ListItemText primary='Info'/>
						</ListItem>
					</List>
					<List>
						<ListItem button key='Grpc Test' component={Link} to="/grpc">
							<ListItemIcon><ImportExportIcon/></ListItemIcon>
							<ListItemText primary='Grpc Test'/>
						</ListItem>
					</List>
				</div>
			</Drawer>
		</div>
	);
}

const mapStateToProps = (state: AppState): object => ({
	nav: state.nav,
});

const matchDispatchToProps = {
	closeNav: setNavClosed,
	toggleNav: toggleNavOpen,
};

export default connect(
	mapStateToProps,
	matchDispatchToProps,
)(NavDrawer);