import React, { useEffect, useState } from 'react';
import { grpc } from '@improbable-eng/grpc-web';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { SessionMsg } from '../../generated/main/grpcweb/revumee_pb';
import version from '../../generated/main/version/version';
import { RevumeeService } from '../../generated/main/grpcweb/revumee_pb_service';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			background: '#FDD835',
			marginTop: theme.spacing(1),
		},
		paper: {
			marginTop: theme.spacing(3),
			width: '80%',
			overflowX: 'auto',
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 200,
			backgroundColor: '#FDD835',
			align: 'center',
		},
	}),
);

const StyledTableCell = withStyles((theme: Theme): StyleRules =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.default,
		},
	}),
)(TableRow);

function Info(): JSX.Element {
	const classes = useStyles();

	const [appServerVersion, setAppServerVersion] = useState('loading...');

	let client: grpc.Client<grpc.ProtobufMessage, grpc.ProtobufMessage>;

	const setupClient = (): void => {
		client = grpc.client(RevumeeService.Session, {
			host: 'https://test.revumee.com:443',
			transport: grpc.WebsocketTransport(),
			debug: true,
		});

		const onMessageFn = (sessionMsg: SessionMsg): void => {
			const sessionControl: SessionMsg.SessionControl = sessionMsg.getSessionControl() as SessionMsg.SessionControl;
			const versionResp: SessionMsg.SessionControl.VersionResp = sessionControl.getVersionResp() as SessionMsg.SessionControl.VersionResp;
			setAppServerVersion(versionResp.getVersion());
			console.log('version', versionResp.getVersion());
		};

		client.onMessage(onMessageFn);

		client.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata): void => {
			console.log('onEnd', status, statusMessage, trailers);
		});

		client.start(new grpc.Metadata({ 'RevumeeService': 'version' }));
	};

	useEffect((): (void | (() => (void | undefined))) => {
		setupClient();
		const msg = new SessionMsg();
		msg.setSessionUuid('12345678');
		const sessionControl = new SessionMsg.SessionControl();
		const versionReq = new SessionMsg.SessionControl.VersionReq();
		versionReq.setSystem(SessionMsg.SessionControl.VersionReq.System.APP_SERVER);
		sessionControl.setVersionReq(versionReq);
		msg.setSessionControl(sessionControl);
		client.send(msg);
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		return function cleanup() {
			client.close();
			return undefined;
		};
	});

	return (
		<div>
			<h2 className={classes.root}>Info</h2>

			<div style={{ width: '100%' }}>
				<Paper className={classes.paper} style={{ width: 400, margin: 'auto' }}>
					<Table className={classes.table} size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell>Description</StyledTableCell>
								<StyledTableCell align="left">Value</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow key='Webapp'>
								<StyledTableCell component="th" scope="row">Webapp Version</StyledTableCell>
								<StyledTableCell align="left">{version}</StyledTableCell>
							</StyledTableRow>
							<StyledTableRow key='AppServer'>
								<StyledTableCell component="th" scope="row">AppServer Version</StyledTableCell>
								<StyledTableCell align="left">{appServerVersion}</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</Paper>
			</div>
		</div>
	);
}

export default connect(
)(Info);