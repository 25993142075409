import React from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			background: '#FDD835',
			marginTop: theme.spacing(1)
		},
	}),
);

function Home(): JSX.Element {
	const classes = useStyles();
	return (
		<h2 className={classes.root}>Home</h2>
	);
}

export default connect(
)(Home);