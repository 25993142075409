import {
	SystemState,
	UPDATE_SESSION,
	SEND_GRPC_MESSAGE,
	RECV_GRPC_MESSAGE,
	NAV_SET_IS_OPEN,
	NAV_SET_IS_CLOSED,
	NAV_TOGGLE_OPEN,
	UpdateSessionAction,
	GrpcState,
	SendGrpcMessageAction,
	RecvGrpcMessageAction,
	NavSetOpenAction,
	NavToggleOpenAction,
	NavSetClosedAction,
} from './types';

export function updateSession(newSession: SystemState): UpdateSessionAction {
	return {
		type: UPDATE_SESSION,
		payload: newSession,
	};
}

export function sendGrpcMessage(newMessage: GrpcState): SendGrpcMessageAction {
	return {
		type: SEND_GRPC_MESSAGE,
		payload: newMessage,
	};
}

export function recvGrpcMessage(newMessage: GrpcState): RecvGrpcMessageAction {
	return {
		type: RECV_GRPC_MESSAGE,
		payload: newMessage,
	};
}

export function setNavOpen(): NavSetOpenAction {
	return {
		type: NAV_SET_IS_OPEN
	};
}

export function setNavClosed(): NavSetClosedAction {
	return {
		type: NAV_SET_IS_CLOSED,
	};
}

export function toggleNavOpen(): NavToggleOpenAction {
	return {
		type: NAV_TOGGLE_OPEN,
	};
}