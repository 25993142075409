import {
	UPDATE_SESSION,
	SEND_GRPC_MESSAGE,
	RECV_GRPC_MESSAGE,
	SystemState,
	SystemActionTypes, GrpcActionTypes, GrpcState, NavOpenState, NavActionTypes, NAV_SET_IS_OPEN, NAV_TOGGLE_OPEN, NAV_SET_IS_CLOSED,
} from './types';

const initialSystemState: SystemState = {
	loggedIn: false,
	session: '',
	userName: '',
};

const initialGrpcState: GrpcState = {
	lastMessage: '',
	timestamp: 0
};

const initialOpenState: NavOpenState = {
	isOpen: false
};

export function systemReducer(
	state = initialSystemState,
	action: SystemActionTypes,
): SystemState {
	switch (action.type) {
		case UPDATE_SESSION: {
			return {
				...state,
				...action.payload,
			};
		}
		default:
			return state;
	}
}

export function grpcReducer(
	state = initialGrpcState,
	action: GrpcActionTypes,
): GrpcState {
	switch (action.type) {
		case SEND_GRPC_MESSAGE:
			return {
				...state,
				...action.payload,
			};
		case RECV_GRPC_MESSAGE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}

export function navOpenStateReducer(
	state = initialOpenState,
	action: NavActionTypes,
): NavOpenState {
	switch (action.type) {
		case NAV_SET_IS_OPEN:
			return {
				...state, isOpen: true
			};
		case NAV_SET_IS_CLOSED:
			return {
				...state, isOpen: false
			};
		case NAV_TOGGLE_OPEN:
			return {
				...state, isOpen: !state.isOpen
			};
		default:
			return state;
	}
}
