import { useEffect } from 'react';
import { connect } from 'react-redux';

import { grpc } from '@improbable-eng/grpc-web';
import { recvGrpcMessage } from '../redux/system/actions';
import { RevumeeService } from '../../generated/main/grpcweb/revumee_pb_service';
import { SessionMsg } from '../../generated/main/grpcweb/revumee_pb';
import doSendGrpMessage from '../redux/thunks';

interface GrpcTestProps {
	thunkSendMessage: (message: string) => void;
}

function GrpcTest(props: GrpcTestProps): null {

	let ready: boolean;

	let client: grpc.Client<grpc.ProtobufMessage, grpc.ProtobufMessage>;

	const setupClient = (): void => {
		client = grpc.client(RevumeeService.Session, {
			host: 'https://test.revumee.com:443',
			transport: grpc.WebsocketTransport(),
			debug: true,
		});

		const onMessageFn = (session: SessionMsg): void => {
			props.thunkSendMessage(session.getSessionUuid());
			// console.log(`onMessage session: ${session} timestamp: ${new Date().getTime()}`);
			ready = true;
		};

		client.onMessage(onMessageFn);

		client.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata): void => {
			console.log('onEnd', status, statusMessage, trailers);
			setupClient();
		});

		client.start(new grpc.Metadata({ 'RevumeeService': 'login' }));
	};

	const sleep = (ms: number): Promise<unknown> =>
		new Promise((resolve): NodeJS.Timeout => setTimeout(resolve, ms));

	const runGrpcTest = async (cmd: SessionMsg.SessionControl): Promise<void> => {
		ready = true;
		for (let i = 1; i <= 100; i += 1) {
			const request = new SessionMsg();
			request.setSessionControl(cmd);
			request.setSessionUuid(`req id: ${i}`);
			ready = false;
			do {
				client.send(request);
				let tries = 0;
				while ((!ready) && (tries < 20)) {
					// eslint-disable-next-line no-await-in-loop
					await sleep(200);
					tries += 1;
				}
			} while (!ready);
		}
		while (!ready) {
			// eslint-disable-next-line no-await-in-loop
			await sleep(200);
		}
		ready = false;
		client.finishSend();
		client.close();
	};

	useEffect((): (void | (() => (void | undefined))) => {
		setupClient();
		const sessionControl = new SessionMsg.SessionControl();
		const versionReq = new SessionMsg.SessionControl.VersionReq();
		versionReq.setSystem(SessionMsg.SessionControl.VersionReq.System.APP_SERVER);
		sessionControl.setVersionReq(versionReq);
		runGrpcTest(sessionControl);
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		return function cleanup() {
			if (ready) {
				client.finishSend();
				client.close();
			}
			return undefined;
		};
	});

	return null;
}

export default connect(
	null,
	{ recvGrpcMessage, thunkSendMessage: doSendGrpMessage },
)(GrpcTest);